<template>
  <div class="emailCRUD">
    <v-card class="mb-1" v-if="formUsr || email.id">
      <v-card-title>
        {{ email.id ? "Edição Email" : "Cadastro de Email" }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="email.name"
          :counter="255"
          label="Nome"
          required
        ></v-text-field>
        <v-text-field
          v-model="email.smtp"
          :counter="255"
          label="url do SMTP"
          required
        ></v-text-field>
        <v-text-field
          v-model.number="email.port"
          :counter="255"
          type="number"
          label="Porta do SMTP"
          required
        ></v-text-field>
        <v-checkbox
          v-model="email.secure"
          label="Usar Segurança"
          required
        ></v-checkbox>
        <v-text-field
          v-model="email.user"
          :counter="255"
          label="Usuário do Email"
          required
        ></v-text-field>
        <v-text-field
          v-model="email.password"
          :counter="255"
          label="Senha do Email"
          required
        ></v-text-field>
        <SelectGroup v-model="email.groupId" />
        <v-text-field
          v-model="email.title"
          :counter="255"
          label="Titulo do E-mail"
        ></v-text-field>
        <v-textarea
          label="Coloque o Corpo do Email"
          v-model="email.body"
          hint="Coloque o Corpo do Email"
        ></v-textarea>
        <v-text-field
          v-model="email.description"
          :counter="255"
          label="Descrição"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" class="mr-4" @click="clearObj"
          >Limpar Campos</v-btn
        >
        <v-btn color="success" class="mr-4" @click="save(email)">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
    <div class="div d-flex justify-end ma-2" v-else>
      <v-btn color="success" @click="formUsr = true">Cadastrar</v-btn>
    </div>
    <v-card>
      <v-card-text>
        <v-simple-table id="tabela" v-show="emails.length">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">Id</th>
                <th class="text-center">Grupo</th>
                <th class="text-center">Nome</th>
                <th class="text-center">Email</th>
                <th class="text-center">Senha</th>
                <th class="text-center">SMTP</th>
                <th class="text-center">Porta</th>
                <th class="text-center">Seguro</th>
                <th class="text-center">Titulo Email</th>
                <th class="text-center">Corpo Email</th>
                <th class="text-center">Descrição</th>
                <th class="text-center">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in emails" :key="index">
                <td class="text-center">{{ item.id }}</td>
                <td class="text-center">{{ item.groupId }}</td>
                <td class="text-center">{{ item.name }}</td>
                <td class="text-center">{{ item.user }}</td>
                <td class="text-center">{{ item.password }}</td>
                <td class="text-center">{{ item.smtp }}</td>
                <td class="text-center">{{ item.port }}</td>
                <td class="text-center">{{ item.seguro }}</td>
                <td class="text-center">{{ item.title }}</td>
                <td class="text-center" v-html="item.body"></td>
                <td class="text-center">{{ item.description }}</td>
                <td class="text-center">
                  <v-icon small class="mr-2" @click="editar(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="delet(item)"> mdi-delete </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import Table from "../../TableGeneric";
// import SelectNiveis from "../../Template/SelectNiveisForm";
import SelectGroup from "../../Template/SelectGroupForm";

export default {
  data() {
    return {
      formUsr: false,
      email: {
        id: null,
        enabled: true,
      },
    };
  },
  computed: {
    workGroup: {
      get() {
        return this.$store.getters.workGroup;
      },
    },
    emails: {
      get() {
        return this.$store.getters.getEmails;
      },
    },
    headers: {
      get() {
        return this.$store.getters.getEmailsTableHeader;
      },
    },
  },
  components: {
    // Table,
    // SelectNiveis,
    SelectGroup,
  },
  methods: {
    get() {
      this.edicao = false;
      this.$store.dispatch("loadEmails");
    },
    save(objeto) {
      this.$store.dispatch("saveEmail", objeto);
      setTimeout(() => {
        this.get();
      }, 500);
    },
    editar(event) {
      this.email = event;
      this.$vuetify.goTo(0, {
        duration: 300,
        offset: 0,
        easing: "easeInOutCubic",
      });
    },
    delet(event) {
      this.$store.dispatch("deleteEmail", event);
    },
    groupSelected() {
      this.get();
    },
    clearObj() {
      Object.assign(this.$data, this.$options.data());
    },
  },
  created() {
    this.get();
  },
};
</script>

<style>
</style>